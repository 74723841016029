import React from 'react'
import Nav from '../components/Nav/Nav'

// temp page to test components for top 100
// ToDo: remove this page when done

const styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  height: '100vh',
  backgroundColor: '#ffffff',
  padding: "100px"
}

const Top100PageComponents = (props) => {
  // const {} = props

  return (
    <>
      <Nav />
      <div style={styles}>
        <h1>Top 100 Components</h1>
      </div>
    </>
  )
}

export default Top100PageComponents
